import { useMutation } from '@apollo/client'
import { ShareSocial } from '@app/components'
import { ROUTES } from '@app/constants'
import { DownvoteDocument, TypesOfContent, UpvoteDocument } from '@app/graphql'
import { UpvoteStatus } from '@app/types'
import { FC, memo, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import Button from '../Button'
import { Icon, IconColor, IconTypes } from '../Icon'
import { useTranslation } from 'react-i18next'
import { useSnapshot } from 'valtio'
import {
  CurrentUserState,
  PrivateChannelState,
  PublicChannelState,
  showSignupPromptModal,
} from '@app/storage'
import { Popup } from '../Modal'

interface FeedPostActionsProps {
  id: string;
  upvotes: number;
  downvotes: number;
  contentType: TypesOfContent;
  commentsAllowed: boolean;
  commentsCount: number;
  status?: UpvoteStatus;
}

const FeedPostActions: FC<FeedPostActionsProps> = ({
  id,
  upvotes,
  downvotes,
  contentType,
  commentsAllowed,
  commentsCount,
  status,
}) => {
  const { t } = useTranslation()
  const { id: currentUserId, guestMode } = useSnapshot(CurrentUserState)

  useEffect(() => {
    if (status) setUpvoteStatus(status)
    else setUpvoteStatus(UpvoteStatus.none)
  }, [])

  const [ upvotesCount, setUpvotesCount ] = useState(null)
  const [ upvoteStatus, setUpvoteStatus ] = useState<UpvoteStatus>(null)
  const [ shareOpened, setShareOpened ] = useState(false)
  const [ shareClosed, setShareClosed ] = useState(false)
  const [ openCopied, setOpenCopied ] = useState(false)
  const [ upvote ] = useMutation(UpvoteDocument)
  const [ downvote ] = useMutation(DownvoteDocument)
  const { isPublic } = useSnapshot(PublicChannelState)

  const navigate = useNavigate()

  useEffect(() => {
    if (!upvotesCount) {
      setUpvotesCount(upvotes - downvotes)
    }
  }, [])

  const onUpvoteStatusChange = (action: UpvoteStatus) => {
    setUpvoteStatus(action)
  }

  const onUpvote = () => {
    onUpvoteClick()
    upvote({ variables: { type: contentType, upvoteId: id } }).catch((e) => {
      console.error(e)
      onDownvoteClick()
    })
  }

  const onDownvote = () => {
    onDownvoteClick()
    downvote({ variables: { type: contentType, downvoteId: id } }).catch(
      (e) => {
        console.error(e)
        onUpvoteClick()
      }
    )
  }

  const onUpvoteClick = () => {
    switch (upvoteStatus) {
      case UpvoteStatus.upvote: {
        onUpvoteStatusChange(UpvoteStatus.none)
        setUpvotesCount(upvotesCount - 1)
        break
      }
      case UpvoteStatus.none: {
        onUpvoteStatusChange(UpvoteStatus.upvote)
        setUpvotesCount(upvotesCount + 1)
        break
      }
      case UpvoteStatus.downvote: {
        onUpvoteStatusChange(UpvoteStatus.upvote)
        setUpvotesCount(upvotesCount + 2)
        break
      }
    }
  }

  const onDownvoteClick = () => {
    switch (upvoteStatus) {
      case UpvoteStatus.upvote: {
        onUpvoteStatusChange(UpvoteStatus.downvote)
        setUpvotesCount(upvotesCount - 2)
        break
      }
      case UpvoteStatus.none: {
        onUpvoteStatusChange(UpvoteStatus.downvote)
        setUpvotesCount(upvotesCount - 1)
        break
      }
      case UpvoteStatus.downvote: {
        onUpvoteStatusChange(UpvoteStatus.none)
        setUpvotesCount(upvotesCount + 1)
        break
      }
    }
  }

  const getContentLink = (): string => {
    return `${window.location.origin.toString()}${
      ROUTES.content.short
    }/${id}?type=${contentType}`
  }

  const onCommentsClick = () => {
    isPublic
      ? (PublicChannelState.scrollPosition = window.scrollY)
      : (PrivateChannelState.scrollPosition = window.scrollY)

    navigate(`${ROUTES.content.short}/${id}?type=${contentType}`)
  }
  const propmtSignupModal = showSignupPromptModal({
    message: t('incognito.modal.loginDefaultMessage'),
    title: t('incognito.modal.loginTitle'),
  })

  const handleOpenModal = () => {
    setShareOpened(false)
    setShareClosed(true)
  }

  return (
    <ActionsContainer>
      <UpvoteSection>
        { currentUserId && <Button
          className="icon"
          onClick={guestMode ? propmtSignupModal : onUpvote}
        >
          {upvoteStatus == UpvoteStatus.upvote ? (
            <Icon
              type={IconTypes.upvoteFilled}
              color={IconColor.brand}
              width={12.5}
              height={18}
            />
          ) : (
            <Icon
              type={IconTypes.upvote}
              color={IconColor.grayDarker}
              width={12.5}
              height={18}
            />
          )}
        </Button>}
        {upvotesCount}
        {currentUserId && <Button
          className="icon"
          onClick={guestMode ? propmtSignupModal : onDownvote}
        >
          {upvoteStatus == UpvoteStatus.downvote ? (
            <Icon
              type={IconTypes.downvoteFilled}
              color={IconColor.warning}
              width={12.5}
              height={18}
            />
          ) : (
            <Icon
              type={IconTypes.downvote}
              color={IconColor.grayDarker}
              width={12.5}
              height={18}
            />
          )}
        </Button>}
      </UpvoteSection>
      {commentsAllowed && (
        <CommentSection>
          <Button
            className="icon"
            onClick={guestMode ? propmtSignupModal : onCommentsClick}
          >
            <Icon
              type={IconTypes.comments}
              color={IconColor.grayDarker}
              width={16.5}
              height={15}
            />
            {commentsCount}
          </Button>
        </CommentSection>
      )}
      <ShareSection>
        <Button className="icon" onClick={() => setShareOpened(true)}>
          <Icon
            type={IconTypes.share}
            color={IconColor.grayDarker}
            width={20}
            height={20}
          />
          {t('common.share')}
        </Button>
      </ShareSection>
      <ShareSocial
        open={shareOpened}
        link={getContentLink()}
        onClose={handleOpenModal}
      />
      <Popup
        open={openCopied}
        closeButtonText={t('common.ok').toUpperCase()}
        headerText={t('success').toUpperCase()}
        onClose={() => localStorage.setItem('socialCopied', null)}
      >
        <span className="vb-font normal darkest">{t('link.copied')}</span>
      </Popup>
    </ActionsContainer>
  )
}

const ActionsContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 0 2rem;

  .icon {
    align-items: center;
    display: flex;
    height: 18px;
    padding: 0 10px;
    background: transparent;
  }
`

const UpvoteSection = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
`

const CommentSection = styled.div`
  display: flex;
  align-items: center;
  margin-left: 1.5rem;
  flex-grow: 1;

  svg {
    margin-right: 0.5rem;
  }
`

const ShareSection = styled.div`
  display: flex;
  align-items: center;
  margin-left: 1.5rem;

  svg {
    margin-right: 0.5rem;
  }
`

export default memo(FeedPostActions)
